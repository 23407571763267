import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { Link, Paper, Typography } from '@mui/material';

export interface TotalProps {
  aastamaks: number;
  registreerimismaks: number;
}

const totalSectionStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#F0F0F0', // Light gray background
  paddingY: '20px', // Increased padding for better spacing
  marginTop: '20px',
  width: '100%',
  boxShadow: '0px -5px 15px 1px rgba(0, 0, 0, 0.1)',
};

const iconStyle = {
  fontSize: '3rem',
  color: '#0061F2', // Blue icon color
};

const TotalSection = (props: TotalProps) => {
  return (
    <Paper
      sx={{
        ...totalSectionStyle,
      }}
    >
      <DirectionsCarIcon sx={{ ...iconStyle }} />
      <Typography variant="h2" fontSize={30} align="center">
        {'Antud sõiduki maksud:'}
      </Typography>
      <Typography variant="h3" fontSize={20} align="center">
        {'Aastamaks: ' + props.aastamaks + ' €'}
      </Typography>
      <Typography variant="h3" fontSize={20} align="center">
        {'Registreerimismaks: ' + props.registreerimismaks + ' €'}
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: '20px', paddingTop: '20px' }}
        align="center"
      >
        {'Oma sõiduki andmed leiate '}
        <Link
          href="https://eteenindus.mnt.ee/public/soidukTaustakontroll.jsf"
          target="_blank"
          rel="noopener noreferrer"
        >
          siit
        </Link>
      </Typography>
      <Typography variant="h5" fontSize={12} align="center">
        {'Uuendatud 31.10.2024'}
      </Typography>
      <Typography variant="h5" fontSize={12} align="center">
        {'Lisatud registreerimismärgi põhjal arvutamine'}
      </Typography>
    </Paper>
  );
};

export default TotalSection;
