import AdapterDateFns from '@date-io/date-fns';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DatePicker} from '@mui/x-date-pickers/DatePicker/DatePicker';
import {et} from 'date-fns/locale';
import React, {useEffect, useState} from 'react';
import {arvutaAastamaks, arvutaRegistreerimismaks, AutoTüüp, CO2Tüüp, MootoriTüüp,} from './CalculateTaxes';
import TotalSection from './TotalSection';
import './styles.css';

export interface ContentProps {
    isMobile: boolean;
}

const Content = (props: ContentProps) => {
    const [useRegNumber, setUseRegNumber] = useState(true); // Toggle between reg number and manual data
    const [regNumber, setRegNumber] = useState<string>('');
    const [auto, setAuto] = useState<AutoTüüp>(AutoTüüp.SÕIDUAUTO);
    const [vanus, setVanus] = useState<number>(0);
    const [mootor, setMootor] = useState<MootoriTüüp>(MootoriTüüp.SISEPÕLEMISMOOTORIGA);
    const [co2Tüüp, setCo2Tüüp] = useState<CO2Tüüp>(CO2Tüüp.WLTP);
    const [co2, setCo2] = useState<number>(0);
    const [mass, setMass] = useState<number>(0);
    const [maht, setMaht] = useState<number>(0);
    const [võimsus, setVõimsus] = useState<number>(0);
    const [aastamaks, setAastamaks] = useState<number>(0);
    const [registreerimismaks, setRegistreerimismaks] = useState<number>(0);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState(false);

    const resetData = () => {
        setRegNumber('');
        setVanus(0);
        setAuto(AutoTüüp.SÕIDUAUTO);
        setMootor(MootoriTüüp.SISEPÕLEMISMOOTORIGA);
        setCo2Tüüp(CO2Tüüp.WLTP);
        setCo2(0);
        setMass(0);
        setMaht(0);
        setVõimsus(0);
        setAastamaks(0);
        setRegistreerimismaks(0);
        setSelectedDate(null);
        setError("");
    };

    useEffect(() => {
        resetData();
    }, [useRegNumber]);

    useEffect(() => {
        if (!useRegNumber) {
            setAastamaks(
                arvutaAastamaks({
                    vanus,
                    auto,
                    mootor,
                    co2Tüüp,
                    co2,
                    mass,
                    maht,
                    võimsus,
                })
            );
            setRegistreerimismaks(
                arvutaRegistreerimismaks({
                    vanus,
                    auto,
                    mootor,
                    co2Tüüp,
                    co2,
                    mass,
                    maht,
                    võimsus,
                })
            );
        }
    }, [useRegNumber, auto, vanus, mootor, co2Tüüp, co2, mass, maht, võimsus]);

    const calculateFromRegNumber = async () => {
        setError("");
        setLoading(true);
        try {
            const response = await fetch(
                `https://us-central1-automaksu-kalkulaator.cloudfunctions.net/proxyVehicleTax?regNr=${regNumber}&showRegFee=1`
            );
            const data = await response.json();

            if (data.errors.length === 0) {
                setAastamaks(data.yearlyFee.totalPrice);
                setRegistreerimismaks(data.regFee.totalPrice);
            } else {
                setError(data.errors[0].messages.et);
                setAastamaks(0);
                setRegistreerimismaks(0);
                console.error("Error fetching tax data:", data.errors);
            }
        } catch (error) {
            console.error("Error fetching tax data:", error);
        } finally {
            setLoading(false); // Set loading to false after data is fetched
        }
    };

    const calculateAge = (date: Date | null) => {
        if (date) {
            const currentDate = new Date();
            const yearsDiff = currentDate.getFullYear() - date.getFullYear();
            if (
                currentDate.getMonth() < date.getMonth() ||
                (currentDate.getMonth() === date.getMonth() &&
                    currentDate.getDate() < date.getDate())
            ) {
                return yearsDiff - 1;
            }
            return yearsDiff;
        }
        return 0;
    };

    return (
        <>
            <Grid item xs={12}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#F0F0F0',
                        color: '#333',
                        flex: 1,
                        marginTop: '20px',
                        boxShadow: '0px -5px 15px 1px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    {/* Toggle selection for Reg Number or Manual Data */}
                    <FormControl sx={{marginTop: '20px', alignItems: 'center'}}>
                        <FormLabel sx={{color: '#333', fontWeight: 'bold'}}>Arvutuse Meetod</FormLabel>
                        <RadioGroup
                            row
                            defaultValue="regNumber"
                            onChange={(e) => setUseRegNumber(e.target.value === 'regNumber')}
                        >
                            <FormControlLabel
                                value="regNumber"
                                control={<Radio sx={{color: '#0061F2'}}/>}
                                label="Registreerimismärgi põhjal"
                            />
                            <FormControlLabel
                                value="manual"
                                control={<Radio sx={{color: '#0061F2'}}/>}
                                label="Sõiduki tehniliste andmete põhjal"
                            />
                        </RadioGroup>
                    </FormControl>

                    {useRegNumber ? (
                        // Registration number input
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <TextField
                                label="Registreerimismärk"
                                value={regNumber}
                                onChange={(e) => setRegNumber(e.target.value)}
                                sx={{margin: '8px', width: '100%'}}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={calculateFromRegNumber}
                                sx={{marginTop: '10px'}}
                            >
                                Arvuta
                            </Button>
                        </Box>
                    ) : (
                        // Manual data input fields
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: props.isMobile ? 'column' : 'row',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-between',
                                    marginTop: '10px',
                                }}
                            >
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={et}>
                                    <DatePicker
                                        label="Esmane registreerimine"
                                        value={selectedDate}
                                        onChange={(date: Date | null) => {
                                            setSelectedDate(date);
                                            const age = calculateAge(date);
                                            setVanus(age);
                                        }}
                                        renderInput={(dateProps) => (
                                            <TextField
                                                sx={{margin: '8px', width: props.isMobile ? '100%' : '48%'}}
                                                {...dateProps}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                                <TextField
                                    label="CO2 väljaheide (g/km)"
                                    type="number"
                                    onChange={(e) => setCo2(Number(e.target.value))}
                                    sx={{margin: '8px', width: props.isMobile ? '100%' : '48%'}}
                                />
                                <TextField
                                    label="Täismass (kg)"
                                    type="number"
                                    onChange={(e) => setMass(Number(e.target.value))}
                                    sx={{margin: '8px', width: props.isMobile ? '100%' : '48%'}}
                                />
                                <TextField
                                    label="Töömaht (cm³)"
                                    type="number"
                                    onChange={(e) => setMaht(Number(e.target.value))}
                                    sx={{margin: '8px', width: props.isMobile ? '100%' : '48%'}}
                                />
                                <TextField
                                    label="Mootori võimsus (kw)"
                                    type="number"
                                    onChange={(e) => setVõimsus(Number(e.target.value))}
                                    sx={{margin: '8px', width: props.isMobile ? '100%' : '48%'}}
                                />
                            </Box>
                        </Box>
                    )}

                    {loading && (
                        <Box display="flex" justifyContent="center" sx={{margin: '8px'}}>
                            <CircularProgress/>
                        </Box>
                    )}

                    {error && (
                        <Typography color="error" sx={{margin: '8px'}}>
                            {error}
                        </Typography>
                    )}

                    <TotalSection
                        aastamaks={aastamaks}
                        registreerimismaks={registreerimismaks}
                    />
                </Box>
            </Grid>
        </>
    );
};

export default Content;