import React, {useEffect} from 'react';

declare global {
    interface Window {
        adsbygoogle: any[];
    }
}

interface GoogleAdProps {
    client: string;
    slot: string;
    style?: React.CSSProperties;
    adFormat?: string;
    responsive?: string;
    layout?: string;
}

const GoogleAd: React.FC<GoogleAdProps> = ({
                                               client,
                                               slot,
                                               style = {display: 'block'},
                                               adFormat = 'auto',
                                               responsive = 'true',
    layout,
                                           }) => {
    useEffect(() => {
        if (typeof window !== 'undefined' && window.adsbygoogle) {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
    }, []);

    return (
        <ins
            className="adsbygoogle"
            style={style}
            data-ad-client={client}
            data-ad-slot={slot}
            data-ad-format={adFormat}
            data-ad-layout={layout}
            data-full-width-responsive={responsive}
        ></ins>
    );
};

export default GoogleAd;
