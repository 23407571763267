export {};

export enum AutoTüüp {
  SÕIDUAUTO = 'SÕIDUAUTO',
  KAUBIK = 'KAUBIK',
  MOOTORRATAS = 'MOOTORRATAS',
  MAASTIKUSÕIDUK = 'MAASTIKUSÕIDUK',
  RATASTRAKTOR = 'RATASTRAKTOR',
}

export enum MootoriTüüp {
  SISEPÕLEMISMOOTORIGA = 'SISEPÕLEMISMOOTORIGA',
  ELEKTRI = 'ELEKTRI',
  HÜBRIID = 'HÜBRIID',
}

export enum CO2Tüüp {
  WLTP = 'WLTP',
  NEDC = 'NEDC',
  PUUDUB = 'PUUDUB',
}

interface AutoProps {
  vanus: number;
  auto: AutoTüüp;
  mootor: MootoriTüüp;
  co2Tüüp: CO2Tüüp;
  co2: number;
  mass: number;
  maht: number;
  võimsus: number;
}

export function arvutaAastamaks(props: AutoProps) {
  let baastasu = 0;
  let lisatasud = 0;
  switch (props.auto.toString()) {
    case AutoTüüp.SÕIDUAUTO.toString(): //CO2 osa - vahemikus 118-150 g/km = 3 €/g; 151-200 = 3,5€/g; 201+ = 4 €/g
      baastasu = 50;
      if (props.mootor.toString() !== MootoriTüüp.ELEKTRI) {
        if (props.co2Tüüp.toString() === CO2Tüüp.WLTP.toString()) {
          switch (true) {
            case props.co2 >= 118 && props.co2 <= 150:
              lisatasud += 3 * (props.co2 - 117);
              break;
            case props.co2 > 150 && props.co2 <= 200:
              lisatasud += (150 - 117) * 3 + 3.5 * (props.co2 - 150);
              break;
            case props.co2 > 200:
              lisatasud +=
                (150 - 117) * 3 + (200 - 150) * 3.5 + 4 * (props.co2 - 200);
          }
        } else if (props.co2Tüüp.toString() === CO2Tüüp.NEDC.toString()) {
          lisatasud += props.co2 * 1.21;
        } else {
          lisatasud +=
            props.võimsus * 0.29 + props.mass * 0.07 + props.vanus * 4.92;
        }
      }

      ///2000 kg täismassi ületav iga kilogramm maksustatakse 0,40 euroga kuni summani 400 eurot, elektriautodel 2400 kg ületav osa kuni 440 euroni

      if (
        props.mootor.toString() ===
          MootoriTüüp.SISEPÕLEMISMOOTORIGA.toString() &&
        props.mass > 2000
      ) {
        const massitasu = (props.mass - 2000) * 0.4;
        if (massitasu <= 400) {
          lisatasud += massitasu;
        } else {
          lisatasud += 400;
        }
      } else if (
        props.mootor.toString() === MootoriTüüp.ELEKTRI.toString() &&
        props.mass > 2400
      ) {
        const massitasu = (props.mass - 2400) * 0.4;
        if (massitasu <= 440) {
          lisatasud += massitasu;
        } else {
          lisatasud += 440;
        }
      } else if (
        props.mootor.toString() === MootoriTüüp.HÜBRIID.toString() &&
        props.mass > 2200
      ) {
        const massitasu = (props.mass - 2200) * 0.4;
        if (massitasu <= 440) {
          lisatasud += massitasu;
        } else {
          lisatasud += 440;
        }
      }

      if (props.vanus >= 1 && props.vanus <= 15) {
        const vanuseKordaja = (100 - (props.vanus - 1) * (90 / 15)) / 100;
        lisatasud = lisatasud * vanuseKordaja;
      } else if (props.vanus > 15) {
        const vanuseKordaja = 0.1;
        lisatasud = lisatasud * vanuseKordaja;
      }

      break;

    case AutoTüüp.KAUBIK.toString():
      baastasu = 50;

      if (props.mootor.toString() === MootoriTüüp.ELEKTRI.toString()) {
        baastasu = 30;
        break;
      }

      //  CO2 osa: 205–250 = 3 eurot, 251–300 = 3,5 eurot ja 301+ = 4 eurot
      if (props.co2Tüüp.toString() === CO2Tüüp.WLTP.toString()) {
        switch (true) {
          case props.co2 >= 205 && props.co2 <= 250:
            lisatasud += 3 * (props.co2 - 204);
            break;
          case props.co2 > 250 && props.co2 <= 300:
            lisatasud += (250 - 204) * 3 + 3.5 * (props.co2 - 250);
            break;
          case props.co2 > 300:
            lisatasud +=
              (250 - 204) * 3 + (300 - 250) * 3.5 + 4 * (props.co2 - 300);
        }
      } else if (props.co2Tüüp.toString() === CO2Tüüp.NEDC.toString()) {
        lisatasud = props.co2 * 1.3;
      } else {
        lisatasud +=
          props.võimsus * 0.4 + props.mass * 0.07 + props.vanus * 5.16;
      }

      if (props.vanus >= 1 && props.vanus <= 15) {
        const vanuseKordaja = (100 - (props.vanus - 1) * (90 / 15)) / 100;
        lisatasud = lisatasud * vanuseKordaja;
      } else if (props.vanus > 15) {
        const vanuseKordaja = 0.1;
        lisatasud = lisatasud * vanuseKordaja;
      }

      break;

    case AutoTüüp.RATASTRAKTOR.toString():
    case AutoTüüp.MOOTORRATAS.toString():
    case AutoTüüp.MAASTIKUSÕIDUK.toString():
      if (props.vanus < 10) {
        switch (true) {
          case props.maht > 50 && props.maht <= 125:
            lisatasud += 30;
            break;
          case props.maht > 125 && props.maht <= 500:
            lisatasud += 45;
            break;
          case props.maht > 500 && props.maht <= 1000:
            lisatasud += 60;
            break;
          case props.maht > 1000 && props.maht <= 1500:
            lisatasud += 75;
            break;
          case props.maht > 1500:
            lisatasud += 90;
            break;
        }
      } else if (props.vanus >= 10 && props.vanus < 20) {
        switch (true) {
          case props.maht > 125 && props.maht <= 500:
            lisatasud += 30;
            break;
          case props.maht > 500 && props.maht <= 1000:
            lisatasud += 45;
            break;
          case props.maht > 1000 && props.maht <= 1500:
            lisatasud += 60;
            break;
          case props.maht > 1500:
            lisatasud += 75;
            break;
        }
      }
  }

  return baastasu + lisatasud;
}

export function arvutaRegistreerimismaks(props: AutoProps) {
  let baastasu = 0;
  let lisatasud = 0;
  switch (props.auto.toString()) {
    case AutoTüüp.SÕIDUAUTO.toString():
      baastasu = 150;

      if (props.mootor.toString() !== MootoriTüüp.ELEKTRI.toString()) {
        if (props.co2Tüüp.toString() === CO2Tüüp.WLTP.toString()) {
          if (props.co2 !== 0) {
            switch (true) {
              case props.co2 >= 1 && props.co2 <= 117:
                lisatasud += 5 * props.co2;
                break;
              case props.co2 >= 118 && props.co2 <= 150:
                lisatasud += 5 * 117 + 10 * (props.co2 - 117);
                break;
              case props.co2 > 150 && props.co2 <= 200:
                lisatasud +=
                  5 * 117 + (150 - 117) * 10 + 30 * (props.co2 - 150);
                console.log(lisatasud)
                break;
              case props.co2 > 200:
                lisatasud +=
                  5 * 117 +
                  (150 - 117) * 10 +
                  (200 - 150) * 30 +
                  50 * (props.co2 - 200);
            }
          }
        } else if (props.co2Tüüp.toString() === CO2Tüüp.NEDC.toString()) {
          lisatasud += props.co2 * 1.21;
        } else {
          lisatasud += lisatasud +=
            props.võimsus * 0.29 + props.mass * 0.07 + props.vanus * 4.92;
        }
      }

      if (
        props.mootor.toString() ===
          MootoriTüüp.SISEPÕLEMISMOOTORIGA.toString() &&
        props.mass > 2000
      ) {
        const massitasu = (props.mass - 2000) * 2;
        if (massitasu <= 2000) {
          lisatasud += massitasu;
        } else {
          lisatasud += 2000;
        }
      } else if (
        props.mootor.toString() === MootoriTüüp.ELEKTRI.toString() &&
        props.mass > 2400
      ) {
        const massitasu = (props.mass - 2400) * 2;
        if (massitasu <= 2200) {
          lisatasud += massitasu;
        } else {
          lisatasud += 2200;
        }
      } else if (
        props.mootor.toString() === MootoriTüüp.HÜBRIID.toString() &&
        props.mass > 2200
      ) {
        const massitasu = (props.mass - 2200) * 2;
        if (massitasu <= 2000) {
          lisatasud += massitasu;
        } else {
          lisatasud += 2000;
        }
      }

      break;

    case AutoTüüp.KAUBIK.toString():
      if (props.mootor.toString() !== MootoriTüüp.ELEKTRI.toString()) {
        baastasu = 300;
        if (props.co2Tüüp.toString() === CO2Tüüp.WLTP.toString()) {
            switch (true) {
              case props.co2 >= 1 && props.co2 <= 204:
                lisatasud += 2 * props.co2;
                break;
              case props.co2 >= 205 && props.co2 <= 250:
                lisatasud += 30 * (props.co2 - 204);
                break;
              case props.co2 > 250 && props.co2 <= 300:
                lisatasud += (250 - 204) * 30 + 35 * (props.co2 - 250);
                break;
              case props.co2 > 300:
                lisatasud +=
                  (250 - 204) * 30 + (300 - 250) * 35 + 40 * (props.co2 - 300);
            }
          }
      } else if (props.co2Tüüp.toString() === CO2Tüüp.NEDC.toString()) {
        lisatasud += props.co2 * 1.3;
      } else {
        lisatasud +=
          props.võimsus * 0.4 + props.mass * 0.07 + props.vanus * 5.16;
      }
      if (props.mootor.toString() === MootoriTüüp.ELEKTRI.toString()) {
        baastasu = 200;
      }

      break;
  }

  if (props.vanus > 1 && props.vanus <= 15) {
    const vanuseKordaja = (100 - (props.vanus - 1) * (90 / 15)) / 100;
    lisatasud = lisatasud * vanuseKordaja;
  } else if (props.vanus > 15 && props.vanus <= 20) {
    const vanuseKordaja = (10 - (props.vanus - 15) * (5 / 5)) / 100;
    lisatasud = lisatasud * vanuseKordaja;
  } else if (props.vanus > 20) {
    const vanuseKordaja = 0.05;
    lisatasud = lisatasud * vanuseKordaja;
  }

  return baastasu + lisatasud;
}
